import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import LabelledSelect from './LabelledSelect';

import { getFullItem } from '../selectors';

import { createFetchInventoryLevel } from '../actions';
import { closePopup } from '../actions/popup';

import { BASE_ZINDEX } from '../popup-factory'
import CheckInventoryLevelTable from './CheckInventoryLevelTable'

class CheckInventoryLevelPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: !props.item.levels,
      color: '',
      size: '',
      item_levels: this.props.item.levels ? this.props.item.levels.results : null
    };

    this.handleFilterColor = this.handleFilterColor.bind(this);
    this.handleFilterSize = this.handleFilterSize.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.onCreateFetchInventoryLevel(this.props.sku, this.props.api_name, this.props.item_id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({loading: false, item_levels: nextProps.item.levels ? nextProps.item.levels.results : null});
  }

  handleFilterColor(e) {
    this.setState({color: e});
    if(e !== '') {
      if(this.state.size === '') {
        this.setState({ item_levels: this.props.item.levels.results.filter(r => r.color === e)});
      }else{
        this.setState({ item_levels: this.props.item.levels.results.filter(r => r.color === e && r.size === this.state.size)});
      }
    }else{
      if(this.state.size === '') {
        this.setState({ item_levels: this.props.item.levels.results });
      }else{
        this.setState({ item_levels: this.props.item.levels.results.filter(r => r.size === this.state.size)});
      }
    }
  }

  handleFilterSize(e) {
    this.setState({size: e});
    if(e !== '') {
      if(this.state.color === '') {
        this.setState({ item_levels: this.props.item.levels.results.filter(r => r.size === e)});
      }else{
        this.setState({ item_levels: this.props.item.levels.results.filter(r => r.size === e && r.color === this.state.color)});
      }
    }else{
      if(this.state.color === '') {
        this.setState({ item_levels: this.props.item.levels.results });
      }else{
        this.setState({ item_levels: this.props.item.levels.results.filter(r => r.color === this.state.color)});
      }
    }
  }

  renderLoading() {
    return (
      <div className="row popup-content column">
        <div className="small-12 columns">
          <div className="small-12 text-center"><br /><img src="/images/gears.gif" /><br /></div>
        </div>
      </div>
    );
  }

  render() {
    const { sku, api_name, item_id, item, onClosePopup, index } = this.props;
    let results = _.get(item, 'levels.results', []);
    const options_color = _.uniqBy(_.map(results, r => {
      return {key: r.color, value: r.color};
    }), 'key');
    const options_size = _.uniqBy(_.map(results, r => {
      return {key: r.size, value: r.size};
    }), 'key');
    if (options_color.length > 0) {
      options_color.unshift({key: '', value: ''});
    }
    if (options_size.length > 0) {
      options_size.unshift({key: '', value: ''});
    }
    const item_levels = this.state.item_levels;
    const title = this.state.loading ? 'Fetching Inventory Level' : !item_levels ? 'No Inventory Level Available' : 'View Inventory Level';

    return (
      <div id="check-inventory-level-modal" className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">{title}</h3>
          </div>
        </div>
        <div className="row popup-content">
          {item.levels && options_color && options_size ?
            <div className="small-12 columns">
              <div className="small-6 columns">
                <LabelledSelect withMarginBottom label="Available colors" options={options_color} value={this.state.color} onChange={e => {this.handleFilterColor(e); }}/>
              </div>
              <div className="small-6 columns">
                <LabelledSelect withMarginBottom label="Available sizes" options={options_size} value={this.state.size} onChange={e => {this.handleFilterSize(e); }}/>
              </div>
            </div>
          : null}
          <div className ="small-12 columns popup-size-limit">
            {this.state.loading ?
              this.renderLoading() :
              <CheckInventoryLevelTable item_levels={item_levels} />
            }
            <br />
            Please verify prices on {item.division_name ? item.division_name : item.origin ? item.origin.division_name : item.product.division_name}'s website.
          </div>
        </div>
        <button className="close-button" aria-label="Close modal" type="button" onClick={e => {e.preventDefault(); onClosePopup();}}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  const item = getFullItem(state, { item_id: ownProps.item_id });
  return {
    item
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    },
    onCreateFetchInventoryLevel: (sku, api_name, item_id) => {
      dispatch(createFetchInventoryLevel(sku, api_name, item_id));
    }
  };
};

const ConnectedCheckInventoryLevelPopup = connect(mapStateToProps, mapDispatchToProps)(CheckInventoryLevelPopup);
export default ConnectedCheckInventoryLevelPopup;

